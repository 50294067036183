/*! (c) Blackbaud, Inc. */
(function (bbi) {
    'use strict';
    
    var appO = {
    	alias: 'bbisite',
    	author: 'Preston Lytton',
    	client: 'theme script',
    	created: '10.16.2017'
    };
    
    function escape(app, bbi, $)
    {
        var methods = {
    		_vars: {},
    		_events: function()
    		{
                $(".escape").on("click", $.proxy(function(e) {
        		    this.getAway();
        		  },this));
        
        		  $(".escape").on("click", $.proxy(function(e) {
        		    // allow the (?) link to work
        		    e.stopPropagation();
        		  },this));
        
        		  $(document).keyup($.proxy(function(e) {
        		    if (e.keyCode == 27) { // escape key
        		      this.getAway();
        		    }
        		  },this));
    		},
    		getAway: function()
    		{
        		window.open("http://weather.com", "_newtab");
    		},
    		initEscape: function(_o)
    		{
                var $m = unescape(_o.message),
                    _h = $('<div />')
                        .append($('<h4 />', {text: 'Safety Exit'}))
                        .append($.parseHTML($m));
                
                $('.escape').attr('data-toggle', 'tooltip');
                $('[data-toggle="tooltip"]').tooltip({
        	        animation:  "true",
                    trigger:    "hover",
                    html:       "true",
                    placement:  "bottom",
                    title:      _h
        	    });
                    
                return this;
    		}
    	};
    
    	return {
    		init: function (options, element)
    		{	
    			$(function()
    			{
    				methods
    				    .initEscape(options)
    				    ._events();
    			});
    		}
    	};
    }
    
    bbi 
        .register(appO)
        .action('Escape', escape)
        .build();

}(window.bbiGetInstance()));